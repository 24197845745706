<template>
  <v-container fluid class="pa-0">
    <v-row dense class="justify-end">
      <v-btn
        color="error"
        text
        x-small
        :disabled="!selectedTeam"
        @click.stop="reset"
      >reset</v-btn>
    </v-row>
    <v-expand-transition>
      <v-row dense v-if="loading && !selectedTeam" class="text-center">
        <v-col cols="12" class="text-h6">
          Let me do some looking up...
        </v-col>
        <v-col cols="12">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row dense v-else-if="!selectedTeam">
        <v-col cols="12" class="text-h6">
          Let's find your {{teamType === 'club' ? 'club': 'team'}}
        </v-col>
        <v-col cols="12" md="6">
          <team-lookup
            :type="teamType"
            v-model="model.name"
            :disabled="disable"
            :limitToClubs="limitToClubs"
            @team-selected="onTeamSelected"
          ></team-lookup>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-expand-transition>
      <v-row dense v-if="selectedTeam">
        <v-col cols="12" class="white">
          <v-container fluid>
            <v-row>
              <v-list>
                <team-search-result-item
                  :team="selectedTeam"
                  :showId="user && user.vblx"
                ></team-search-result-item>
              </v-list>
            </v-row>
            <v-slide-x-reverse-transition mode="out-in" group leave-absolute>
              <v-row dense key="invalid" v-if="!valid || manualContact">
                <v-expand-transition>
                  <v-col cols="12" v-if="user" class="py-0">
                    <div class="text-h6">Are you the primary contact for this registration?</div>
                    <v-radio-group v-model="useUser" row>
                      <v-radio label="Yes" :value="true" color="success"></v-radio>
                      <v-radio label="No" :value="false" color="success"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-expand-transition>
                <v-expand-transition>
                  <v-col cols="12" v-if="!user || useUser === false" class="py-0">
                    <div class="text-h6">Who is the primary contact for this registration?</div>
                    <v-expand-transition>
                        <div v-if="loading">
                          <v-progress-circular indeterminate color="color3"></v-progress-circular>
                        </div>
                      </v-expand-transition>
                      <v-expand-transition>
                        <div v-if="!loading && contacts.length">
                          <v-btn
                            color="color3 color3Text--text"
                            class="mr-3"
                            v-for="c in contacts"
                            :key="c.id"
                            x-small
                            @click.stop="selectContact(c)"
                          >
                            {{c.name}}
                          </v-btn>
                          <v-btn
                            color="color3Text color3--text"
                            class="mr-3"
                            x-small
                            @click.stop="onOtherClick"
                          >
                            Other
                          </v-btn>
                        </div>
                      </v-expand-transition>
                  </v-col>
                </v-expand-transition>
              </v-row>
              <v-row dense key="invalid2" v-if="(manualContact || ((!user || useUser === false) && !contacts.length))">
                <v-col cols="12" key="c1">
                  <v-text-field
                    :label="`${primaryContact.title} Name*`"
                    v-model="primaryContact.name"
                    color="color3"
                    :disabled="disable"
                    :hide-details="!!disable"
                    hint="Please enter their full name"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" key="c2">
                  <v-text-field
                    :label="`${primaryContact.title} Phone*`"
                    v-model="primaryContact.phone"
                    color="color3"
                    :disabled="disable"
                    :hide-details="!!disable"
                    v-mask="'(###) ###-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" key="c3">
                  <v-text-field
                    :label="`${primaryContact.title} Email*`"
                    v-model="primaryContact.email"
                    color="color3"
                    :disabled="disable"
                    :hide-details="!!disable"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense key="valid" v-if="valid">
                <v-col cols="12">
                  <span class="font-weight-black">{{primaryContact.title}}:</span><span class="ml-3">{{primaryContact.name}}</span>
                  <v-btn color="error" text icon x-small class="ml-3" @click="clearUser">
                    <v-icon>fas fa-trash</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-slide-x-reverse-transition>
          </v-container>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapGetters } from 'vuex'
import ValidUSPhone from '@/helpers/ValidUSPhone'
import ValidEmail from '@/helpers/ValidEmail'
const TeamLookup = () => import('@/components/Team/Rostered/Search/TeamLookup.vue')
const TeamSearchResultItem = () => import('@/components/Team/Rostered/Search/SearchResultItem.vue')

export default {
  directives: { mask },
  props: ['model', 'disable', 'adminQuickAdd', 'teamType', 'tournament', 'active'],
  data () {
    return {
      selectedTeam: null,
      showSearch: true,
      loading: false,
      contacts: [],
      useUser: null,
      manualContact: false,
      bids: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    valid () {
      const m = this.model
      const c = m.contacts.length > 0 && m.contacts[0]
      return m && (m.id || (m.name && m.yesOrNo)) && c && (c.id || (c.name && ValidEmail(c.email) && ValidUSPhone(c.phone)))
    },
    primaryContact () {
      const m = this.model
      return m && m.contacts.length > 0 && m.contacts[0]
    },
    limitToClubs () {
      if (this.bids && this.bids.length) {
        return this.bids.map(b => b.teamSearchView)
      }
      return this.tournament.is('club-bids') ? [] : false
    }
  },
  methods: {
    onTeamSelected (team) {
      this.getTeamContacts(team.id)
      this.selectedTeam = team
      this.model.id = team.id
      this.model.name = team.name
      this.clearUser()
      this.emitBids()
    },
    getTeamContacts (id) {
      this.loading = true
      this.$VBL.roster.contacts.get(id)
        .then(r => {
          this.contacts = r.data
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    },
    setUser () {
      this.manualContact = false
      this.model.contacts = [{
        title: 'Primary Contact',
        id: this.user.id,
        name: this.user.fullname
      }]
    },
    selectContact (c) {
      this.manualContact = false
      this.model.contacts = [{
        title: 'Primary Contact',
        id: c.id,
        name: c.name
      }]
    },
    clearUser () {
      this.model.contacts = []
      this.addContact('Primary Contact')
      if (this.useUser) this.useUser = null
    },
    onOtherClick () {
      this.clearUser()
      this.manualContact = true
    },
    addContact (title) {
      this.model.contacts.push({
        title: title,
        name: null,
        phone: null,
        email: null
      })
    },
    isRequired (i, field) {
      return this.requiredFields && this.requiredFields.length > i && this.requiredFields[i] && this.requiredFields[i].includes(field)
    },
    reset () {
      this.selectedTeam = null
      this.clearUser()
      this.useUser = null
    },
    getClubBids () {
      this.loading = true
      this.$VBL.club.getBids(this.tournament.id, this.user.id)
        .then(r => {
          this.bids = r.data
          if (r.data.length === 1) {
            this.onTeamSelected(r.data[0].teamSearchView)
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    emitBids () {
      if (this.bids.length > 0 && this.selectedTeam) {
        const b = this.bids.find(f => f.club.id === this.selectedTeam.id)

        if (b) {
          this.$emit('club-bid', b)
        }
      }
    }
  },
  watch: {
    valid: function (val) {
      this.model.valid = val
    },
    useUser: function (val) {
      val ? this.setUser() : this.clearUser()
    },
    active: function (val) {
      if (val && !this.selectedTeam && this.tournament.is('club-bids')) {
        this.getClubBids()
      }
    }
  },
  mounted () {
    const m = this.model
    if (!(m.id || (m.name && m.yesOrNo))) this.clearUser()
    if (this.valid) this.model.valid = true
    if (this.tournament.is('club-bids')) {
      this.getClubBids()
    }
  },
  components: {
    TeamLookup,
    TeamSearchResultItem
  }
}
</script>

<style>

</style>
